import React, { useEffect, useState } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import isMonday from 'date-fns/isMonday';
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import { addDays, isWednesday, isWithinInterval } from 'date-fns';

const InputDate = (props) => {


    const updateInput = (value) => {
        props.setCommande({
            ...props.commande,
            ['retraitDate']: value
        })
    }

    const dateRange = (day) => {
       return (
           isMonday(day) ||
           isWednesday(day) ||
           isWithinInterval(day, { start: new Date(2024, 11, 23), end: new Date(2025, 0, 5) }) ||
           isWithinInterval(day, { start: new Date(2025, 1, 24), end: new Date(2025, 2, 2) }) ||
           isWithinInterval(day, { start: new Date(2025, 3, 22), end: new Date(2025, 4, 4) })
       )
       
    }


    useEffect(()=>{
        const maxTry = 50;
        for (let count=3; count < maxTry; count++) {
            let minimum = addDays(new Date(), count);
            if (!dateRange(minimum)) return props.setCommande({
                ...props.commande,
                retraitDate: minimum
             });           ;
           
        }
    },[])



    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
       <DatePicker
          shouldDisableDate={dateRange}
          label="Choisissez une date"
          value={props.commande.retraitDate}
          onChange={updateInput}
          minDate={props.commande.retraitDate}
          renderInput={(params) => <TextField {...params} />}
        />
    </LocalizationProvider>
    );
};

export default InputDate;